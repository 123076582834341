import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core"
import Layout from "../components/layout/main"
import Lead from "../components/global/text/lead"
import FiltreGaleri from "../components/global/carousel/filtregaleri"
import FiltreKullanimAlani from "../components/global/filtre/kullanimalani"

import TeknikTablo from "../components/global/filtre/tekniktablo"
import Seo from "../components/global/seo/seo.jsx"
const PaslanmazElekTeli = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "filtre-galeri/paslanmazfiltre13.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title="Paslanmaz Elek Mesh Telleri - Paslanmaz Filtre Erez Kaynak " 
        description="Paslanmaz elek filtre telleri ve örgü mesh telleri 304 kalite ve 316 kalite olarak yurt dışından ithal edilmiş olarak stoklarımızda bulunmaktadır. Standart olarak rulo halinde olup, 100cm, 130cm, 150cm ve 200cm yüksekliğinde 30mt uzunluğunda rulo halinde paslanmaz filtre ve elek tellerimiz hazır bulunmaktadır."
        keywords="mesh tel,elek teli,elek tel,paslanmaz mesh,304 mesh tel,304 elek teli,316 mesh tel,316 elek teli,Krom Tel Elek, Paslanmaz Elek Teli, Mesh Elek, Paslanmaz Krom Süzgeç Teli, Krom Elek Teli, Filtre Teli, Krom Tel, Tel Elek, Mikron Tel, Krom Nikel Elek Teli,elek teli kullanım alanları,Çelik Tel Elek,Paslanmaz elek teli, Paslanmaz kafes teli, Paslanmaz örgülü tel, Elek teli, Kafes teli, Örgülü tel, Paslanmaz tel örgü, Paslanmaz tel elek, Örme tel, Tel kafes, 304 elek teli, 310 elek teli, 316 elek teli,paslanmaz elek teli, çelik elek, kafes teli, analiz elek, delikli sac, perfore sac, perfore alüminyum, gaz filitre teli, konveyör bant, filtre teli, süzgeç teli, tel elek, 304 paslanmaz, 316 paslanmaz, kafes imalatı, prinç elek teli, daire filtre, mesh elek, 18 mesh elek, 20 mesh elek, 30 mesh elek, 10*10 elek, 15x15 elek, elekteli.com, filitre teli,paslanmaz elek fiyatı,paslanmaz elek fiyatları,paslanmaz filtre fiyatları,elek teli fiyatları,elek teli fiyatı"
        url="paslanmaz-elek-teli"
        imgurl={data.file.childImageSharp.thumb.src}
      />

      <Box my={3}>
        <Paper elevation={4}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box px={3} pt={3}>
                <Box textAlign="center">
                  <Typography variant="h4" component="h1">
                    <Box fontWeight={500}>
                      Paslanmaz Filtre ve Elek Mesh Teller
                    </Box>
                  </Typography>
                </Box>
                <Lead>
                  <span>Paslanmaz elek filtre telleri</span> ve{" "}
                  <span>örgü mesh telleri</span> 304 kalite ve 316 kalite olarak
                  yurt dışından ithal edilmiş olarak stoklarımızda
                  bulunmaktadır.
                </Lead>
                <Lead>
                  Standart olarak rulo halinde olup, 100cm, 130cm, 150cm ve
                  200cm yüksekliğinde 30mt uzunluğunda rulo halinde{" "}
                  <span>paslanmaz filtre ve elek tellerimiz</span> hazır
                  bulunmaktadır.
                </Lead>
                <Lead>
                  Göz arası 5mm ve üzeri olan{" "}
                  <span>paslanmaz filtre ve elek tellerinin</span> istenilen
                  ebatlarda galvaniz, 304 kalite yada 316 kalite olarak üretimi
                  yapılmaktadır.
                </Lead>
                <Lead>
                  <span>304 kalite filtre ve elek tellerimiz;</span> 450 C’ye
                  kadar dayanıklıdır. İçerisinde nikel olduğundan dolayı
                  korozyon direnci yüksektir.{" "}
                  <span>304 kalite filtre ve elek telleri</span> 18-8 olarak ta
                  sınıflandırılmaktadır. Bunun sebebi içerisindeki bileşenlerin
                  %18 krom, %8 ise nikel oranında olduğunu gösterir.
                </Lead>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box px={3} pt={3}>
                <Img
                  className="img-fluid MuiPaper-elevation5 MuiPaper-rounded"
                  fluid={data.file.childImageSharp.thumb}
                  loading="eager"
                  fadeIn={false}
                  alt="Paslanmaz Filtre ve Elek Mesh Teller"
                />
              </Box>
            </Grid>
          </Grid>
          <Box px={3} pt={1}>
            <Lead>
              <span>316 kalite filtre ve elek tellerimiz;</span> 650 C’ye kadar
              dayanıklıdır. İçerisinde 304 kaliteye ek olarak %2 oranında extra
              olarak Molibden içerir.Molibden maddesi ile filtre ve elek teli
              asit, tuz ve deniz suyu gibi çevresel faktörlere karşı ekstra
              dayanıklı hale gelir.{" "}
              <span>316 kalite filtre ve elek telleri</span> 18-10 olarak ta
              sınıflandırılmaktadır. Bunun sebebi içerisindeki bileşenlerin %18
              krom, %10 ise nikel oranında olduğunu gösterir ve yüksek
              oksidasyon mukavemeti sağlar. Kopma ve bükülme direnci çok iyidir.
            </Lead>
            <Lead>
              Detaylı bilgi ya da fiyat teklifi almak için bizi arayınız.
            </Lead>
          </Box>
          <Box textAlign="center" py={3}>
            <Link to="/iletisim" style={{ textDecoration: "none" }}>
              <Button variant="outlined" color="secondary">
                İLETİŞİM BİLGİLERİ
              </Button>
            </Link>
          </Box>
        </Paper>
      </Box>
      <Box py={3}>
        <Paper elevation={4}>
          <Box p={3}>
            <FiltreKullanimAlani title="Filtre ve Elek Telleri Kullanım Alanları">
              <Lead>
                <span>Paslanmaz filtre ve elek tellerimiz</span> her türlü
                hammadde ve malzeme elemede, süzgeç ve filtre teli olarak yaygın
                olarak kullanılırlar. Özellikle yüksek sıcaklığa dayanabilmesi,
                su ve asidik ortamlarda çok rahatlıkla kullanılabilmesi ve üstün
                mekanik özellikleri bakımından yaygın olarak{" "}
                <span>paslanmaz filtre ve elek tellerin</span> tercih edilme
                nedenlerindendir.
              </Lead>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box px={3}>
                    <ul>
                      <li className="lead">Filtre Sanayi</li>
                      <li className="lead">Plastik Sanayi</li>
                      <li className="lead">Kağıt Sanayi</li>
                      <li className="lead">Kimya Sanayi</li>
                      <li className="lead">Gıda Sanayi</li>
                      <li className="lead">İlaç Sanayi</li>
                    </ul>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box px={3}>
                    <ul>
                      <li className="lead">Otomotiv Sanayi</li>
                      <li className="lead">Mutfak Sanayi</li>
                      <li className="lead">Gemi Sanayi</li>
                      <li className="lead">Petrokimya Sanayi</li>
                      <li className="lead">Makine ve imalat Sanayi</li>
                    </ul>
                  </Box>
                </Grid>
              </Grid>
            </FiltreKullanimAlani>
          </Box>
        </Paper>
      </Box>
      <Box my={3}>
        <FiltreGaleri />
      </Box>
      <Box my={3}>
        <TeknikTablo />
      </Box>
    </Layout>
  )
}
export default PaslanmazElekTeli
